/* custom-calendar.css */
/* custom-calendar.css */
.custom-calendar .rbc-calendar, /* Atau targetkan secara langsung jika tidak menggunakan pembungkus */
.rbc-calendar {
  font-family: "Manrope", sans-serif;
}

/* Mengganti font untuk elemen spesifik kalender */
.rbc-toolbar,
.rbc-event,
.rbc-btn-group button {
  font-family: "Manrope", sans-serif;
}

.rbc-toolbar {
  display: flex;
  flex-direction: column;
  align-items: center; /* Untuk menengahkan item-item secara horizontal */
  justify-content: center; /* Untuk distribusi ruang secara vertikal */
  color: #086d59;
  font-size: 16px;
  font-weight: 700;

  margin-top: 10px; /* Jarak atas */
  margin-bottom: 10px; /* Jarak bawah */
}

.rbc-btn-group button {
  background-color: #086d59;
  color: white;
  border: none;
  padding: 5px 10px;
  margin: 0 2px;
  border-radius: 4px;
  font-size: 12px; /* Atur ukuran font yang lebih kecil */
  margin-top: 10px;
  margin-bottom: 10px;
}

.rbc-btn-group button:hover {
  background-color: #0a886f;
}

.rbc-off-range-bg {
  background-color: #f5f5f5;
}

.rbc-event {
  background-color: #086d59;
  color: white;
  border-radius: 4px;
  padding: 2px 5px;
  border: none;
  font-size: 12px; /* Atur ukuran font yang lebih kecil */
}

.rbc-event:hover {
  background-color: #0a886f;
}

.rbc-selected {
  background-color: #0a886f;
}

.rbc-today {
  background-color: #efefef;
}
